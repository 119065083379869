@import '../../styles/_variables.scss';

@media (min-width: $desktop) {
  .search_container {
    height: calculateRem(20);
    border-radius: calculateRem(50);
    background: #ffffff;
    padding: calculateRem(10);
    position: absolute;
    box-shadow: 0px 4px 12px rgba(0, 34, 51, 0.08),
      0px 2px 4px rgba(0, 34, 51, 0.16);
    right: calculateRem(20);
    top: calculateRem(60);
    display: flex;
    z-index: 1;
    transform: translateY(-50%);
    cursor: pointer;

    img {
      align-self: center;
    }

    .search {
      font-family: 'Inter';
      font-size: calculateRem(15);
      border: none;
      background: none;
      display: none;
      outline: none;
      transition: 0.4s;
      line-height: calculateRem(15);
      width: calculateRem(20);
      &::placeholder {
        color: #939ba1;
      }
    }
    .searchResults {
      display: none;
      width: 100%;
    }
  }

  .isOpen {
    width: calculateRem(240);
    .search {
      width: calculateRem(240);
      display: block;
    }
  }
  .isSearching {
    .search_container {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-right-radius: calculateRem(20);
      border-top-left-radius: calculateRem(20);
      cursor: default;
    }
    .searchResults {
      z-index: 99;
      display: block;
      position: absolute;
      top: calculateRem(40);
      background: #ffffff;
      left: 0;
      box-shadow: 0 0 0 rgba(0, 34, 51, 0.08), 0px 2px 4px rgba(0, 34, 51, 0.16);
    }
  }
}

@media (max-width: $desktop) {
  .search_container {
    display: flex;
    flex: 1;
    border: 1px solid #eef2f5;
    border-radius: calculateRem(4);
    overflow: hidden;
    margin: calculateRem(16);
    img {
      padding: calculateRem(5);
      align-self: center;
    }
    .search {
      font-family: 'Inter';
      font-size: calculateRem(15);
      flex: 1;
      border: none;
      outline: none;
      &::placeholder {
        color: #939ba1;
      }
    }

    .searchResults {
      z-index: 99;
      top: calculateRem(100);
      width: 94vw;
      margin-left: calculateRem(-16);
    }
  }
}
