@import '../../common/styles/variables';

.dim {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 20;

  transition-duration: 0.4s;
  transition-property: opacity;
}

.dim:not(.dimVisible) {
  opacity: 0;
  pointer-events: none;
}

.cnt {
  width: 40px;
  height: 40px;
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 20px;
  top: 60px;
  border-radius: 50%;
  background: #fff;
  z-index: 1;
  box-shadow: 0px 4px 12px rgba(0, 34, 51, 0.08),
    0px 2px 4px rgba(0, 34, 51, 0.16);
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 24;

  @media (min-width: $desktop) {
    display: flex;
  }
}

.cnt:before {
  content: '';
  width: 16px;
  height: 6px;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
}

.dropDown {
  width: 320px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.4s;
}

.dropDown.visible {
  opacity: 1;
}

.dropDown:not(.visible) {
  pointer-events: none;
}

.dropDown .categorySelector > * {
  margin: 0;
}

.dropDown .categorySelector * {
  user-select: none;
}
