$purple: #887af9;
$orange: #fe8f59;
$blue: #51d0f0;
$dark-blue: #26a69a;
$navy: #2727f9;
$pink: #d37ef4;
$green: #7cda00;
$red: #fa6565;
$yellow: #ffca0f;

$purple-bg: rgba(
  $color: $purple,
  $alpha: 0.15,
);

$orange-bg: rgba(
  $color: $orange,
  $alpha: 0.15,
);

$yellow-bg: rgba(
  $color: $yellow,
  $alpha: 0.15,
);

$pink-bg: rgba(
  $color: $pink,
  $alpha: 0.15,
);

$blue-bg: rgba(
  $color: $blue,
  $alpha: 0.15,
);

$dark-blue-bg: rgba(
  $color: $dark-blue,
  $alpha: 0.15,
);

$navy-bg: rgba(
  $color: $navy,
  $alpha: 0.15,
);

$green-bg: rgba(
  $color: $green,
  $alpha: 0.15,
);

$red-bg: rgba(
  $color: $red,
  $alpha: 0.15,
);

$link-color: #4360df;
$text-color: #939ba1;
$headline-color: #000;
$background: #fff;
