@import '../../common/styles/variables';

.categories {
  opacity: 1;
}

.open {
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 34, 51, 0.08),
    0px 2px 4px rgba(0, 34, 51, 0.16);
  padding-top: calculateRem(12);
  margin: calculateRem(12) calculateRem(16);
  position: absolute;
  background: $background;
  width: calc(100% - 32px);
  top: 0;
  z-index: 1;

  h2 {
    color: $text-color;
    font-family: $font;
    font-size: calculateRem(13);
    margin: 0;
    font-weight: normal;
  }
}

.openHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 calculateRem(16);
  margin-bottom: calculateRem(12);
}

.openHeader.spacing {
  margin-top: 31px;
}

.openButton {
  display: block;
  background: $background;
  border: none;
  font-family: $font;
  font-size: calculateRem(15);
  color: $headline-color;
  display: flex;
  width: 100%;
  cursor: pointer;
  align-content: center;
  line-height: calculateRem(22);
  padding: calculateRem(10) calculateRem(16);

  &:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  svg {
    margin-right: calculateRem(19);
    fill: $headline-color;
  }
}

.openButton.submitDapp {
  margin-top: 24px;
}

.selected {
  background: $purple-bg;
  color: $purple;

  svg {
    fill: $purple;
  }
}

.closed {
  display: flex;
  width: 100%;
  margin: calculateRem(12) calculateRem(16);
  padding: calculateRem(10) calculateRem(15);
  width: calc(100% - 32px);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1),
    0px 2px 6px rgba(136, 122, 249, 0.2);
  border: none;
  border-radius: 8px;
  color: $background;
  font-family: $font;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
}

.closedText {
  display: flex;
  align-items: center;

  svg {
    fill: $background;
    margin-right: calculateRem(19);
  }
}

.EXCHANGES {
  background: $purple;
}

.DEFI {
  background: $dark-blue;
}

.MARKETPLACES {
  background: $orange;
}

.COLLECTIBLES {
  background: $blue;
}

.GAMES {
  background: $pink;
}

.SOCIAL_NETWORKS {
  background: $green;
}

.UTILITIES {
  background: $red;
}

.CRYPTO_ONRAMPS {
  background: $navy;
}

.OTHER {
  background: $yellow;
  color: $headline-color;
}
