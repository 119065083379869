@import '../../common/styles/variables';

.modalWindow {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: $font;
}

@media (min-width: $desktop) {
  .modalWindow {
    height: auto;
  }
}

.modalContentFullScreen {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

@media (min-width: $desktop) {
  .modalContentFullScreen {
    height: 512px;
  }
}

.cntWithImgControl {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.title {
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  font-size: 13px;
  border-bottom: 1px solid #f7f7f7;
}

.withImgControl {
  display: none;
}

.hasTransaction {
  text-align: center;
  padding: 16px 26px;
}

.block {
  padding: 0 16px 16px 16px;

  .labelRow {
    height: 42px;
    display: flex;
    align-items: center;

    span:nth-child(1) {
      font-size: 15px;
    }

    span:nth-child(2) {
      color: #939ba1;
      font-size: 12px;
      margin-left: auto;
    }
  }

  .input {
    width: 100%;
    height: 52px;
    box-sizing: border-box;
    padding: 15px;
    border: none;
    border-radius: 8px;
    margin: 0;
    background: #eef2f5;
  }

  textarea.input {
    height: 92px;
    resize: none;
  }

  .imgCnt {
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 50%;
    border: 1px dashed #939ba1;
    margin: 16px auto;
    background: #eef2f5;

    span {
      color: #939ba1;
      font-size: 15px;
    }

    .imgHolder {
      width: inherit;
      height: inherit;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: inherit;
      background-size: cover;
      background-position: center;
    }

    input.uploader {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      cursor: pointer;
    }
  }

  .imgInfo {
    line-height: 22px;
    color: #939ba1;
    font-size: 15px;
  }

  .categorySelector {
    position: relative;
  }

  .categorySelector > div {
    width: 100%;
    top: auto;
    bottom: 0;
    margin: 0;
  }

  .categorySelector > button {
    width: 100%;
    margin: 0;

    transition-property: background;
    transition-duration: 0.4s;
  }

  .categorySelectorEmpty > button {
    background: #939ba1;
  }
}

.blockSubmit {
  display: flex;
  flex-direction: column;
  margin: 40px 0 16px 0;

  .terms {
    line-height: 22px;
    color: #939ba1;
    font-size: 15px;
    cursor: pointer;

    a {
      color: $link-color;
      text-decoration: none;
    }
  }

  .submitButton {
    background: $link-color;
    border-radius: 8px;
    color: #fff;
    margin: calculateRem(26) auto 0 auto;
    border: none;
    font-family: $font;
    padding: calculateRem(11) calculateRem(38);
    font-size: calculateRem(15);
    cursor: pointer;
  }

  .submitButton:disabled,
  .submitButton[disabled] {
    background: $text-color;
  }
}

.imgControl {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1 1 auto;

  .imgCanvasCnt {
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .imgCanvas {
      width: 160px;
      height: 160px;
      border-radius: 50%;
      pointer-events: none;
    }
  }

  .controls {
    width: 100%;
    border-top: 1px solid #eef2f5;

    .slider {
      height: 74px;
      display: flex;
      align-items: center;

      * {
        flex: 1 1 auto;
      }

      .minZoom {
        width: 10px;
        height: 10px;
        flex: 0 0 auto;
        border: 1px solid #939ba1;
        border-radius: 3px;
        margin: auto 30px auto 15px;
      }

      .maxZoom {
        width: 18px;
        height: 18px;
        flex: 0 0 auto;
        border: 1px solid #939ba1;
        border-radius: 3px;
        margin: auto 11px auto 26px;
      }
    }

    .actionsCnt {
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 16px;

      .button {
        height: 44px;
        border-radius: 8px;
        border: none;
        border-radius: 22px;
        font-family: $font;
        padding: calculateRem(11) calculateRem(38);
        font-size: calculateRem(15);
        cursor: pointer;
      }

      .cancelButton {
        color: $link-color;
        background: #eceffc;
      }

      .doneButton {
        color: #fff;
        background: $link-color;
      }
    }
  }
}

/* rating */
.dapp {
  height: 48px;
  display: flex;
  align-items: center;
  font-family: $font;
  font-weight: 500;
  padding: 0 calculateRem(12);
}

.dapp .image {
  max-width: 24px;
  max-height: 24px;
  border-radius: 50%;
  margin-right: calculateRem(12);
}

.items {
  display: flex;
  flex-direction: column;
  font-family: $font;
}

.items .itemRow {
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 calculateRem(12);
}

.items .item {
  display: flex;
  align-items: center;
}

.items .item img {
  margin-right: calculateRem(12);
}

.badge {
  border-radius: 24px;
  color: #ffffff;
  font-family: $font;
  font-size: calculateRem(15);
  margin-right: calculateRem(16);
  margin-left: auto;
  padding: calculateRem(3) calculateRem(10);
}

.greenBadge {
  @extend .badge;
  background: #44d058;
}

.redBadge {
  @extend .badge;
  background: #f00;
}

.inputArea {
  width: calc(100% - 2 * 16px);
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
}

.inputArea.inputAreaBorder {
  border-bottom: 1px solid #eef2f5;
}

.inputArea input {
  width: 19px;
  border: none;
  text-align: center;
  font-size: calculateRem(32);
  line-height: calculateRem(28);
  font-family: $font;
  margin-right: calculateRem(6);
}

.inputArea input:focus {
  outline: none;
}

.inputArea::after {
  transition: all 0.05s ease-in-out;
  content: 'SNT';
  color: #939ba1;
  font-size: calculateRem(32);
  line-height: calculateRem(28);
  font-family: $font;
}

.inputArea span {
  font-size: calculateRem(32);
  line-height: calculateRem(28);
  font-family: $font;
  margin-right: calculateRem(6);
}

.footer {
  text-align: center;
}

.footer button {
  background: $link-color;
  border-radius: 8px;
  color: #fff;
  margin: calculateRem(10) auto;
  border: none;
  font-family: $font;
  padding: calculateRem(11) calculateRem(38);
  font-size: calculateRem(15);
  cursor: pointer;
}

.footer button:disabled,
.footer button[disabled] {
  background: $text-color;
}

.footer .disclaimer {
  font-size: calculateRem(15);
  color: $text-color;
  line-height: 22px;
  font-family: $font;
  padding: calculateRem(16);
  border-bottom: 1px solid #eef2f5;
  margin: 0;
}

.footer .disclaimer a {
  text-decoration: none;
  color: $link-color;
}
