@import '../../common/styles/variables';

.cnt {
  font-family: $font;
}

.title {
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 17px;
  border-bottom: 1px solid #f7f7f7;
}

.frame {
  position: relative;
  padding: 16px;
}

.frameTitle {
  color: #939ba1;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 16px;
}

.frameImportant {
  line-height: 22px;
  font-size: 15px;
  font-weight: 500;
  padding: 16px;
  border: 1px solid #f7f7f7;
  border-radius: 16px;

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.frameContent {
  line-height: 22px;
  font-size: 15px;
  margin-top: 32px;
  overflow-y: hidden;

  transition-duration: 0.4s;
  transition-property: max-height;

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.readMoreCnt {
  width: 100%;
  height: 100%;
  max-height: 108px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
}

.readMore {
  height: 40px;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 15px;
  border-radius: 20px;
  padding: 0 30px;
  margin-bottom: 16px;
  background: #4360df;
  cursor: pointer;
}
