@import '../../common/styles/variables';

.headline {
  font-family: $font;
  font-size: calculateRem(17);
  margin-left: calculateRem(15);
  margin-bottom: calculateRem(10);
}

.grid {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(3, 1fr);
  grid-gap: calculateRem(20);
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  margin-left: 1.1rem;

  @media (min-width: $desktop) {
    // grid-auto-flow: row;
    grid-template-columns: repeat(3, 1fr);
  }
}
